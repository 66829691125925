export enum DepotType {
    SINGLE = 'SINGLE',
    JOINT = 'JOINT',
    UNDERAGE = 'UNDERAGE',
}

export interface Purchase {
    orderId: string;
    portfolioId: string;
    portfolioName: string;
    portfolioManaged: boolean;
    monthlyRate?: number;
    balance?: number;
    depotType: DepotType;
    pensionPlan: boolean;
}
