import { MatomoTrackingService } from '@base/tracking/matomo/matomo-tracking.service';
import { MatomoScriptLoader } from '@base/tracking/matomo/matomo-script-loader';
import { ConsentManagementInitializer } from '@base/consent-management/consent-management-initializer';
import { ConversionStatus, ITrackingService } from '@base/tracking/tracking-service';
import { WebComponentIntegrationService } from '@base/web-components/web-component-integration.service';
import { loadConfigurations } from '@vv-frontend/config-lib';
import { getConfiguration, isToggleActive } from '@base/config';

export class Tracking {
    private matomoScriptLoader: MatomoScriptLoader;
    private readonly trackingFactory: Tracking;

    constructor(private readonly webComponentIntegrationService = new WebComponentIntegrationService(), trackingFactory: Tracking | null = null) {
        this.trackingFactory = trackingFactory || this;
    }

    /**
     * Encapsulate function for starting consent management
     */
    public getConsentManagementInitializer(trackingService: ITrackingService | null): ConsentManagementInitializer {
        return new ConsentManagementInitializer(trackingService);
    }

    /**
     * Encapsulated function for starting tracking.
     */
    public getTrackingService(nonce: string): ITrackingService | null {
        const matomoTagManagerScriptUrl = getConfiguration('matomoScriptUrl');
        if (matomoTagManagerScriptUrl) {
            this.matomoScriptLoader = new MatomoScriptLoader(matomoTagManagerScriptUrl, nonce);

            if (isToggleActive('enableTracking')) {
                return new MatomoTrackingService(this.matomoScriptLoader);
            }
        }
        return null;
    }

    public async setupTracking(application: string, nonce: string, eventTarget?: EventTarget): Promise<void> {
        await this.initializeTracking(application, nonce);

        if (eventTarget) {
            this.dispatchReadyEvent(eventTarget);
        }
    }

    private async initializeTracking(application: string, nonce: string) {
        const trackingService = this.trackingFactory.getTrackingService(nonce);
        this.initConsentManagement(trackingService);
        await this.setupTrackingInstanceData(trackingService, application);
    }

    private initConsentManagement(trackingService: ITrackingService | null) {
        const usercentricsScriptUrl = getConfiguration('usercentricsScriptUrl');
        const usercentricsSettingsId = getConfiguration('usercentricsSettingsId');
        if (isToggleActive('consentBannerActivatedWeb') && usercentricsScriptUrl && usercentricsSettingsId) {
            const consentManagementInitializer = this.trackingFactory.getConsentManagementInitializer(trackingService);
            consentManagementInitializer?.initializeConsentManagement(
                {
                    usercentricsScriptUrl,
                    usercentricsSettingsId,
                },
                this.matomoScriptLoader,
                getConfiguration('trackingPixels')
            );
        }
    }

    private async setupTrackingInstanceData(trackingService: ITrackingService | null, application: string): Promise<void> {
        const conversionStatus = await this.setupUserSpecificTracking();

        trackingService?.setConversionStatus(conversionStatus);
        trackingService?.setApplication(application);

        await trackingService?.buildApplicationData();
    }

    async setupUserSpecificTracking(): Promise<ConversionStatus> {
        const vvAuth = await this.webComponentIntegrationService.vvAuth();
        if (vvAuth && (await vvAuth.isLoggedIn())) {
            const roles = await vvAuth.getRoles();
            if (roles.indexOf('customer') >= 0) {
                // Customer
                return ConversionStatus.KUNDE;
            } else {
                return ConversionStatus.INTERESSENT;
            }
        } else {
            return ConversionStatus.BESUCHER;
        }
    }

    private dispatchReadyEvent(eventTarget: EventTarget): void {
        const customEvent = new CustomEvent('vv-tracking-state', {
            bubbles: true,
            detail: {
                'new-state': 'ready',
            },
        });
        eventTarget.dispatchEvent(customEvent);
    }
}

export class WebTrackingElement extends HTMLElement {
    private readonly tracking = new Tracking();

    public connectedCallback(): void {
        const application = this.getAttribute('application');
        const nonce = this.getAttribute('vvnonce') || '';
        if (!application) {
            throw new Error(`Could not setup tracking: You need to set the 'application' property.`);
        }
        loadConfigurations().then(() => {
            this.tracking.setupTracking(application, nonce, this).catch((r) => console.error(`Could not setup tracking: ${r}`));
        });
    }
}
