import { MatomoScriptLoader } from './matomo-script-loader';
import { CUSTOM_EVENT_TYPE_PUSH_TO_MTM, VvTrackingEventDetails } from '@base/tracking/tracking-service';

export class MatomoTagManagerService {
    private readonly theWindow: Window = window;

    private readonly mtmEventListener = (evt: CustomEvent<VvTrackingEventDetails>) => this.trackCustomEvent(evt);

    constructor(private readonly matomoScriptLoader: MatomoScriptLoader) {}

    private async trackCustomEvent({ detail }: CustomEvent<VvTrackingEventDetails>) {
        if (detail.event) {
            await this.pushToTagManager(detail);
        }
    }

    // used by unit tests to clean up the jsdom
    public detach(): void {
        this.theWindow.document.removeEventListener(CUSTOM_EVENT_TYPE_PUSH_TO_MTM, this.mtmEventListener);
    }

    async pushToTagManager(event: VvTrackingEventDetails) {
        const dl = await this.matomoScriptLoader.dataLayer();
        try {
            ((dataLayer) => dataLayer._mtm)(dl)?.push(event);
        } catch (error) {
            // silently ignored here
        }
    }

    registerMtmEventChangedListener() {
        this.theWindow.document.addEventListener(CUSTOM_EVENT_TYPE_PUSH_TO_MTM, this.mtmEventListener);
    }
}
