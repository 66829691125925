export interface Pushable {
    push: (anyObject: any) => void;
}

export interface MatomoDataLayer {
    _mtm?: Pushable;
    _paq?: Pushable;
}

export class MatomoScriptLoader {
    private readonly theWindow: Window & MatomoDataLayer = window as any;

    private dataLayerFunction: Promise<MatomoDataLayer>;

    constructor(private readonly scriptUrl: string, private readonly nonce: string) {}

    public dataLayer(): Promise<MatomoDataLayer> {
        if (!this.dataLayerFunction) {
            this.dataLayerFunction = this.registerScripts();
        }
        return this.dataLayerFunction;
    }

    /**
     * adds the MTM-Script-Tag to the head
     */
    private registerScripts(): Promise<MatomoDataLayer> {
        return new Promise<MatomoDataLayer>((resolve, reject) => {
            this.theWindow._mtm = [{ 'mtm.startTime': new Date().getTime(), event: 'mtm.Start' }];
            this.theWindow._paq = [];
            const script = this.theWindow.document.createElement('script');
            script.type = 'text/javascript';
            script.async = true;
            script.defer = true;
            script.setAttribute('nonce', this.nonce);
            script.src = this.scriptUrl;
            script.addEventListener('load', () => {
                resolve({
                    _mtm: this.theWindow._mtm,
                    _paq: this.theWindow._paq,
                });
            });
            script.addEventListener('error', () => reject(new Error(`Could not load script from ${this.scriptUrl}`)));
            this.theWindow.document.head.append(script);
        });
    }
}
