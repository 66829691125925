import { Purchase } from '@base/tracking/purchase';

export const CUSTOM_EVENT_TYPE_VVTRACKING = 'vv-tracking-event';
export const CUSTOM_EVENT_TYPE_PUSH_TO_MTM = 'vv-tracking-mtm';

export interface VvTrackingEventDetails {
    event: string;

    [x: string]: any;
}

export interface PurchaseEventDetails extends VvTrackingEventDetails, Purchase {}

export function isPurchaseEventDetails(t: VvTrackingEventDetails): t is PurchaseEventDetails {
    return t.event === 'purchase';
}

export interface ApplicationData extends VvTrackingEventDetails {
    application?: string;
    conversionStatus?: ConversionStatus;
}

export enum ConversionStatus {
    BESUCHER = 'Besucher',
    INTERESSENT = 'Interessent',
    KUNDE = 'Kunde',
}

export abstract class ITrackingService {
    protected _optOut = true;

    private consentIsSet = false;
    private eventsEmittedBeforeConsentIsSet: VvTrackingEventDetails[] = [];

    set optOut(optOut: boolean) {
        this._optOut = optOut;

        if (!this.consentIsSet) {
            this.consentIsSet = true;
            this.processInitialEventQueue();
        }
    }

    async push(event: VvTrackingEventDetails): Promise<void> {
        if (!this.consentIsSet) {
            this.eventsEmittedBeforeConsentIsSet.push(event);
            return;
        }

        if (this.trackingEnabled()) {
            await this.pushEvent(event);
        }
    }

    /* Used in initialization */

    /** sets 'application' in the applicationData */
    abstract setApplication(application: string): void;

    /**
     * resets the 'conversionStatus' field in the applicationData,
     * and tracks an 'applicationData' event
     */
    abstract setConversionStatus(conversionStatus: ConversionStatus): void;

    /* Actual tracking events */

    /**
     * Tracks an 'applicationData' event with the applicationData set.
     * Must be called before any other tracking event
     */
    abstract buildApplicationData(): Promise<void>;

    private processInitialEventQueue() {
        for (const event of this.eventsEmittedBeforeConsentIsSet) {
            this.push(event);
        }
    }

    protected trackingEnabled(): boolean {
        return !this._optOut;
    }

    protected abstract pushEvent(event: VvTrackingEventDetails);
}
