import { createGetConfiguration, createIsToggleActive } from '@vv-frontend/config-lib';

export const isToggleActive = createIsToggleActive<FeatureToggles>();
export const getConfiguration = createGetConfiguration<Configurations>();

export interface FeatureToggles {
    consentBannerActivatedWeb: boolean;
    enableTracking: boolean;
    consentBannerActivated: boolean;
}

export interface Configurations {
    usercentricsScriptUrl: string;
    usercentricsSettingsId: string;
    trackingPixels: string;
    matomoScriptUrl: string;
}
