import { MatomoDataLayer, MatomoScriptLoader, Pushable } from './matomo-script-loader';
import {
    ApplicationData,
    CUSTOM_EVENT_TYPE_VVTRACKING,
    isPurchaseEventDetails,
    ITrackingService,
    ConversionStatus,
    VvTrackingEventDetails,
} from '@base/tracking/tracking-service';
import { ProductVariantUtil } from '@base/tracking/matomo/product-variant-util';
import { DepotType, Purchase } from '@base/tracking/purchase';

enum EcommerceEvents {
    ADD_ITEM = 'addEcommerceItem',
    TRACK_ORDER = 'trackEcommerceOrder',
}

export enum EventTypesWithPreprocessing {
    VIRT_PATH = 'virtPath',
}

export class MatomoTrackingService extends ITrackingService {
    private static readonly MAX_NUMBER_OF_OUTSTANDING_EVENTS = 100;

    private readonly theWindow: Window = window;

    applicationData: ApplicationData = { event: 'applicationData' };
    private readonly eventListener = (evt: CustomEvent<VvTrackingEventDetails>) => this.trackCustomEvent(evt);
    private numberOfOutstandingEvents = 0;

    constructor(private readonly matomoScriptLoader: MatomoScriptLoader) {
        super();
        this.theWindow.document.addEventListener(CUSTOM_EVENT_TYPE_VVTRACKING, this.eventListener);

        const hasOptOutCookie = this.hasOptOutCookie();
        this.matomoSDKOptOut(hasOptOutCookie);

        if (hasOptOutCookie) {
            this.optOut = true;
        }
    }

    protected trackingEnabled(): boolean {
        if (this.hasOptOutCookie()) {
            return false;
        }

        return super.trackingEnabled();
    }

    private hasOptOutCookie(): boolean {
        // Cookie name needs to match name in privacy-frontend
        return document.cookie.split(';').some((item) => item.trim().startsWith('VV_MATOMO_OPT_OUT=1'));
    }

    // used by unit tests to clean up the jsdom
    public detach(): void {
        this.theWindow.document.removeEventListener(CUSTOM_EVENT_TYPE_VVTRACKING, this.eventListener);
    }
    get theApplicationData(): ApplicationData {
        return this.applicationData;
    }

    /* Custom Dimensions */
    setApplication(application: string): void {
        this.applicationData.application = application;
    }
    setConversionStatus(conversionType: ConversionStatus): void {
        this.applicationData.conversionStatus = conversionType;
    }

    public buildApplicationData(): Promise<void> {
        return this.push(this.applicationData);
    }

    protected async pushEvent(event: VvTrackingEventDetails) {
        if (isPurchaseEventDetails(event)) {
            await this.handlePurchase(event);
        } else {
            await this.pushToTagManager(event);
        }
    }

    private async trackCustomEvent({ detail }: CustomEvent<VvTrackingEventDetails>) {
        if (detail.event) {
            detail = this.preprocessEvent(detail);
            await this.push(detail);
        }
    }

    private preprocessEvent(detail: VvTrackingEventDetails): VvTrackingEventDetails {
        if (detail.event === EventTypesWithPreprocessing.VIRT_PATH) {
            detail.virtPath = MatomoTrackingService.generateVirtPath(detail);
        }
        return detail;
    }

    private static generateVirtPath(detail: VvTrackingEventDetails) {
        const virtPath: string = String(detail.virtPath).startsWith('/') ? detail.virtPath : `/${detail.virtPath}`;
        return `${window.location.origin}${virtPath}`;
    }

    private async handlePurchase(purchase: Purchase) {
        const productId = purchase.portfolioId;
        const depotType = MatomoTrackingService.depotType(purchase);

        if (purchase.balance) {
            const productName = `${depotType} Einmalzahlung`;
            const productCategory = ProductVariantUtil.getVVProductVariant(purchase);
            await this.addEcommerceItem(`${productId}`, productName, productCategory, purchase.balance);
        }

        if (purchase.monthlyRate) {
            const productName = `${depotType} Sparplan`;
            const productCategory = `${ProductVariantUtil.getVVProductVariant(purchase)}-LS`;
            await this.addEcommerceItem(`${productId}-LS`, productName, productCategory, purchase.monthlyRate);
        }
        await this.submitItemsWithTotalPrice(purchase);
    }

    private async submitItemsWithTotalPrice(purchase: Purchase) {
        await this.pushToAnalytics([
            EcommerceEvents.TRACK_ORDER,
            purchase.orderId, // (required) Unique Order ID
            (purchase.balance || 0) + (purchase.monthlyRate || 0), // (required) Order Revenue grand total (includes tax, shipping, and subtracted discount)
        ]);
    }

    private async pushToTagManager(event: VvTrackingEventDetails) {
        await this.pushToMatomo(event, (dataLayer) => dataLayer._mtm);
    }

    private async pushToAnalytics(event) {
        await this.pushToMatomo(event, (dataLayer) => dataLayer._paq);
    }

    private async pushToMatomo(event, getPushableFromDataLayer: (dataLayer: MatomoDataLayer) => Pushable | undefined) {
        if (this.numberOfOutstandingEvents < MatomoTrackingService.MAX_NUMBER_OF_OUTSTANDING_EVENTS && this.trackingEnabled()) {
            this.numberOfOutstandingEvents++;
            const dl = await this.matomoScriptLoader.dataLayer();
            try {
                getPushableFromDataLayer(dl)?.push(event);
            } catch (error) {
                // silently ignored here
            } finally {
                this.numberOfOutstandingEvents = Math.max(0, this.numberOfOutstandingEvents - 1);
            }
        }
    }

    private async addEcommerceItem(productId, productName: string, category: string, price): Promise<void> {
        await this.pushToAnalytics([
            EcommerceEvents.ADD_ITEM,
            productId, // (required) SKU: Product unique identifier
            productName, // (optional) Product name
            category, // (optional) Product category. You can also specify an array of up to 5 categories eg. ["Books", "New releases", "Biography"]
            price, // (recommended) Product price
            1, // (optional, default to 1) Product quantity
        ]);
    }

    private static depotType(purchase: Purchase) {
        if (purchase.pensionPlan) {
            return 'V4L';
        }
        switch (purchase.depotType) {
            case DepotType.UNDERAGE:
                return 'Junior';
            case DepotType.JOINT:
            // can not happen in VV
            default:
                return 'Regular';
        }
    }

    private async matomoSDKOptOut(optOut: boolean): Promise<void> {
        const dl = await this.matomoScriptLoader.dataLayer();
        try {
            if (optOut) {
                dl._paq?.push(['optUserOut']);
            } else {
                dl._paq?.push(['forgetUserOptOut']);
            }
        } catch (error) {
            // silently ignored here
        }
    }
}
