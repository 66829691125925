export interface VvAuthentication {
    getRoles: () => Promise<string[]>;
    getEmail: () => Promise<string>;
    isLoggedIn: () => Promise<boolean>;
    getToken: () => Promise<string>;
}

export class WebComponentIntegrationService {
    constructor(private readonly theWindow: Window = window) {}

    public vvAuth(): Promise<VvAuthentication | null> {
        return this.getComponent('vv-auth');
    }

    private async getComponent<T>(componentName: string): Promise<T | null> {
        const component = this.theWindow.document.getElementsByTagName(componentName)[0];
        if (!!component) {
            await this.theWindow.customElements.whenDefined(componentName);
            return component as unknown as T;
        }
        return null;
    }
}
