import { Purchase } from '@base/tracking/purchase';

export class ProductVariantUtil {
    public static getVVProductVariant(purchase: Purchase): 'Green' | 'Aktiv' | 'ETF' {
        if (purchase.portfolioName.includes('Green')) {
            return 'Green';
        } else if (purchase.portfolioManaged) {
            return 'Aktiv';
        } else {
            return 'ETF';
        }
    }
}
