import { CUSTOM_EVENT_TYPE_PUSH_TO_MTM, ITrackingService } from '@base/tracking/tracking-service';
import { MatomoTagManagerService } from '@base/tracking/matomo/matomo-tag-manager.service';
import { MatomoScriptLoader } from '@base/tracking/matomo/matomo-script-loader';

export interface ConsentConfig {
    usercentricsScriptUrl: string;
    usercentricsSettingsId: string;
}

enum PossibleConsents {
    MATOMO = 'Matomo (self hosted)',
}

interface ConsentEvent {
    detail: {
        event: string;
        [x: string]: any;
    };
}

const CONSENT_STATUS_EVENT_NAME = 'UC_CONSENT_STATUS';
const CONSENT_STATUS = 'consent_status';
const CONSENT_TYPE = 'explicit';

const vvToUsercentricNameObj = {
    matomo_tag_manager: 'Matomo Tag Manager',
    facebook_tracking_pixel: 'Facebook Pixel',
    google_tracking_pixel: 'Google Ads',
    outbrain_tracking_pixel: 'Outbrain Pixel',
};

export class ConsentManagementInitializer {
    private readonly theWindow: Window = window;
    private trackingPixels?: string[];

    private readonly consentListener = (event) => this.onConsentStatusChanged(this.trackingService, event);

    constructor(private readonly trackingService: ITrackingService | null) {}

    public initializeConsentManagement(config: ConsentConfig, matomoScriptLoader: MatomoScriptLoader, trackingPixels?: string) {
        this.registerConsentScript(config);
        this.registerConsentStatusChangedListener();
        const mtm = new MatomoTagManagerService(matomoScriptLoader);
        mtm.registerMtmEventChangedListener();
        this.trackingPixels = trackingPixels?.split(',').map((p) => vvToUsercentricNameObj[p]);
    }

    // used by unit tests to clean up the jsdom
    public detachConsentStatusChangedListener() {
        this.theWindow.removeEventListener(CONSENT_STATUS_EVENT_NAME, this.consentListener);
    }

    private registerConsentScript(config: ConsentConfig) {
        const script = this.theWindow.document.createElement('script');
        script.defer = true;
        script.setAttribute('data-settings-id', config.usercentricsSettingsId);
        script.id = 'usercentrics-cmp';
        script.src = config.usercentricsScriptUrl;
        this.theWindow.document.head.append(script);
    }

    private registerConsentStatusChangedListener() {
        this.theWindow.addEventListener(CONSENT_STATUS_EVENT_NAME, this.consentListener);
    }

    private onConsentStatusChanged(trackingService: ITrackingService | null, event: ConsentEvent) {
        if (this.isExplicitConsentStatusEvent(event) && trackingService) {
            trackingService.optOut = !event.detail[PossibleConsents.MATOMO];
        }
        this.addTrackingPixels(event);
    }

    private addTrackingPixels(event: ConsentEvent): void {
        for (const key in event.detail) {
            if (this.trackingPixels?.includes(key) && event.detail[key] === true) {
                document.dispatchEvent(this.getTrackingPixelEvent(key));
            }
        }
    }

    private getTrackingPixelEvent(pixel: string): CustomEvent {
        return new CustomEvent(CUSTOM_EVENT_TYPE_PUSH_TO_MTM, {
            bubbles: true,
            detail: {
                event: pixel,
            },
        });
    }

    /**
     * Returns true, if the event is a "consent_status"-event and has type "explicit"
     * We need the type check, because an "implicit"-type event is dispatched automatically by usercentrics,
     * which would set {@link ITrackingService._optOut} to true.
     * Thus the first event entering a page is not being sent {@link ITrackingService.push}.
     * @param event to handle
     */
    private isExplicitConsentStatusEvent(event: ConsentEvent): boolean {
        return event.detail.event === CONSENT_STATUS && event.detail.type === CONSENT_TYPE;
    }
}
